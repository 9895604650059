import React, { Component } from "react";
import IzlagacForma from "./IzlagacForma";
import Cookies from "js-cookie";
import axios from "axios";
import Config from "../../config.json";
import jwt from "jsonwebtoken";
import IzlagacImport from "./IzlagacImport";

class Izlagaci extends Component {
  state = {
    popis: [],
    eventid: 104,
    izlagacForma: false,
    importIzlagacaForma: false,
    popisZaImport: [],
  };

  componentDidMount() {
    //this.auth();
    this.refresh();
    this.ucitajListuZaImport();
  }

  refresh = async () => {
    const url = Config.apiEndpoint + "/event/:event/izlagac/list";
    const response = await axios.get(url, {
      headers: {
        authorization: Cookies.get("token"),
        eventid: this.state.eventid,
      },
    });
    console.log("POPIS IZLAGAČA: ", response);
    if (response.data.error) {
      return false;
    }
    this.setState({
      popis: response.data,
    });
  };

  ucitajListuZaImport = async () => {
    const urlImport =
      Config.apiEndpoint + "/event/:event/izlagac/lista-za-import";
    const response = await axios.get(urlImport, {
      headers: {
        authorization: Cookies.get("token"),
        eventid: this.state.eventid,
      },
    });
    console.log("POPIS IZLAGAČA: ", response);
    if (response.data.error) {
      return false;
    }
    this.setState({
      popisZaImport: response.data,
    });
  };

  otvoriIzlagaca = (izlagac) => {
    !this.props.noviEvent &&
      this.props.history.push(`/event/izlagac/${izlagac}`);
    console.log("izlagac otvoren.");
  };

  auth = async () => {
    const token = Cookies.get("token");

    if (token) {
      const response = await axios.get(Config.apiEndpoint + "/check-token", {
        headers: {
          token: token,
          active_user: jwt.decode(token).id,
        },
      });
      if (response.data.response !== "Check passed") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  };

  changeEvent = (event) => {
    this.setState(
      {
        eventid: event.target.value,
      },
      () => this.refresh()
    );
  };

  zatvoriFormu = () => {
    this.setState({
      izlagacForma: !this.state.izlagacForma,
    });
  };

  importIzlagaca = () => {
    this.setState({
      importIzlagacaForma: !this.state.importIzlagacaForma,
    });
  };

  onImport = () => {
    this.refresh();
    this.importIzlagaca();
  };

  render() {
    let id_eventa = 0;
    if (this.props.match && this.props.match.params.event) {
      id_eventa = this.props.match.params.event;
    } else {
      id_eventa = this.props.event;
    }
    console.log(id_eventa);
    return (
      <div>
        <div>
          <select
            name='eventSwitcher'
            id='eventSwitcher'
            value={this.state.eventid}
            onChange={(e) => this.changeEvent(e)}
          >
            <option value='100'>HoReCa Adria 2019</option>
            <option value='102'>HoReCa Adria 2020</option>
            <option value='104'>HoReCa Adria 2021</option>
          </select>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              {!this.props.noviEvent && (
                <div className='text-right'>
                  {this.state.eventid === 102 && (
                    <button
                      className='btn btn-secondary mr-2 mb-2'
                      onClick={this.importIzlagaca}
                    >
                      Import izlagača sa prošlih sajmova
                    </button>
                  )}
                  <button
                    className='btn btn-primary text-right mb-2'
                    onClick={() =>
                      this.setState({ izlagacForma: !this.state.izlagacForma })
                    }
                  >
                    Upis novog izlagača
                  </button>
                </div>
              )}
              {this.state.importIzlagacaForma && (
                <IzlagacImport
                  popisZaImport={this.state.popisZaImport}
                  onImport={this.onImport}
                />
              )}
              {this.state.eventid}
              {this.state.izlagacForma && (
                <IzlagacForma
                  eventid={this.state.eventid}
                  zatvoriFormu={this.zatvoriFormu}
                  refresh={this.refresh}
                />
              )}
              <table className='table table-sm'>
                <thead>
                  <tr>
                    <th className='naslov-popis'>Naziv</th>
                    <th className='naslov-popis'>Mjesto</th>
                    <th className='naslov-popis'>Pozicija</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.popis.map((izlagac) => (
                    <tr
                      key={izlagac.id}
                      className={
                        this.props.noviEvent
                          ? "novi-event-izlagaci-row"
                          : "event-izlagaci-row"
                      }
                      onClick={() => this.otvoriIzlagaca(izlagac.id)}
                    >
                      {this.props.noviEvent && (
                        <td>
                          <input type='checkbox' checked />
                        </td>
                      )}
                      <td>{izlagac.naziv}</td>
                      <td>{izlagac.grad_mjesto}</td>
                      <td>{izlagac.broj_standa}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Izlagaci;
