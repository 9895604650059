import React, { Component } from "react";
import axios from "axios";
import Config from "../../config.json";
import "./EventKategorije.css";

class EventKategorije extends Component {
  state = {
    popis: []
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    let event = 0;
    if (this.props.match && this.props.match.params.id) {
      event = this.props.match.params.id;
    } else {
      event = this.props.event;
    }
    const url = Config.apiEndpoint + "/event/100/categories/list";
    const response = await axios.get(url, {
      headers: {
        event: event
      }
    });
    this.setState({ popis: response.data });
  };

  render() {
    return (
      <div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              {/* <div className="text-right">
                <Link
                  to={`/event/${this.props.match.params.event}/categories/create`}
                >
                  <button className="btn btn-primary mb-2">
                    Upis nove kategorije
                  </button>
                </Link>
              </div> */}
              <table className='table'>
                <thead>
                  <tr>
                    <th>Naziv kategorije</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.popis.map(kategorija => (
                    <tr
                      key={kategorija.id}
                      className={
                        this.props.noviEvent
                          ? "novi-event-import-row"
                          : "event-kategorije-row"
                      }
                    >
                      <td>
                        {this.props.noviEvent && (
                          <input type='checkbox' checked />
                        )}{" "}
                        {kategorija.naziv}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventKategorije;
