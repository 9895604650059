import React from "react";

import Logo from "../../images/logoRA2022.png";

import "./PrintZebra.css";

const PrintZebra = (props) => {
  /*
  if (kategorija === "") {
    kategorija = "Posjetitelj";
  }
  */

  return (
    <div
      id='print'
      style={{
        paddingTop: "0mm",
        marginLeft: "128px",
        marginTop: "137px",
        textAlign: "left",
        backgroundColor: "white",
      }}
    >
      <div style={{ width: "100mm", textAlign: "center" }}>
        <div
          className='posjetitelj_rotate rotate'
          style={{ transform: "rotate(180deg)", marginTop: "25mm" }}
        >
          {props.osoba.kategorija === ""
            ? "Posjetitelj "
            : props.osoba.kategorija}
        </div>
        <div className='friendlyid_rotate rotate'>{props.osoba.friendlyid}</div>
        <div
          id='qrcode_rotated rotate'
          style={{
            margin: "0px auto",
            marginTop: "20px",
            display: "inline-block",
          }}
        >
          <img
            src={`https://api.horeca-adria.com/prijava/barcode.php?barcode=${props.osoba.barcode}`}
            alt='barcode'
            style={{ height: "60px", width: "250px" }}
          />
        </div>
        <div className='mjesto_rotated rotate'>{props.osoba.grad_mjesto}</div>
        <div className='tvrtka_rotated rotate'>{props.osoba.tvrtka}</div>
        <div className='prezime_rotated rotate'>{props.osoba.prezime}</div>
        <div className='ime_rotated rotate'>{props.osoba.ime}</div>
        <div className='logo_rotated rotate'>
          <img src={Logo} alt='' />
        </div>
        {props.osoba.tvrtka && props.osoba.tvrtka.length > 35 ? (
          <div className='logo' style={{ marginTop: "10mm" }}>
            <img src={Logo} alt='' />
          </div>
        ) : (
          <div className='logo' style={{ marginTop: "40mm" }}>
            <img src={Logo} alt='' />
          </div>
        )}
        <div className='ime'>{props.osoba.ime}</div>
        <div className='prezime'>{props.osoba.prezime}</div>
        <div className='tvrtka'>{props.osoba.tvrtka}</div>
        <div className='mjesto'>{props.osoba.grad_mjesto}</div>
        <div className='qrcode'>
          <img
            src={`https://api.horeca-adria.com/prijava/barcode.php?barcode=${props.osoba.barcode}`}
            alt='barcode'
            style={{ height: "60px", width: "250px" }}
          />
        </div>
        <div className='friendlyid' style={{ textAlign: "left" }}>
          {props.osoba.friendlyid}
        </div>
        <div className='posjetitelj' style={{ marginTop: "5px" }}>
          {props.osoba.kategorija === ""
            ? "Posjetitelj "
            : props.osoba.kategorija}
        </div>
      </div>
    </div>
  );
};

export default PrintZebra;
