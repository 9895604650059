import React, { Component } from "react";
import { Link } from "react-router-dom";
import Config from "../../config.json";
import "./PopisOrganizatora.css";

class PopisOrganizatora extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popis: []
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    fetch(`${Config.apiEndpoint}/popis-organizatora/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          popis: res
        });
      })
      .catch(err => console.log(err));
  };

  edit = id => {
    this.props.history.push(`/organizator/edit/${id}`);
  };

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="upis-organizatora">
                <Link to="/organizator/upis">
                  <button className="btn btn-secondary mb-2">
                    Upis organizatora
                  </button>
                </Link>
              </div>
              <table className="table">
                <tbody>
                  {this.state.popis.map(organizator => (
                    <tr key={organizator.id}>
                      <td>{organizator.naziv}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => this.edit(organizator.id)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopisOrganizatora;
