import React, { Component } from "react";

class UpisOrganizatora extends Component {
  state = {
    naziv: "",
    username: "",
    password: ""
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    fetch("http://localhost:8081/organizator/create", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        organizator: this.state
      })
    })
      .then(res => {
        console.table(res);
        this.props.history.push("/organizator");
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <h2>Upis novog organizatora</h2>
        <form onSubmit={this.handleSubmit}>
          <div>
            Naziv organizatora:
            <input type="text" name="naziv" onChange={this.handleChange} />
          </div>
          <div>
            Username:
            <input type="text" name="username" onChange={this.handleChange} />
          </div>
          <div>
            Password:
            <input
              type="password"
              name="password"
              onChange={this.handleChange}
            />
          </div>
          <button>Upiši</button>
        </form>
      </div>
    );
  }
}

export default UpisOrganizatora;
