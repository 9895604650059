import React, { Component } from "react";
import Config from "../../config.json";
import axios from "axios";
import IzlagacPredstavnici from "./IzlagacPredstavnici.jsx";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";
import IzlagacGosti from "./IzlagacGosti.jsx";

class IzlagacDetalji extends Component {
  state = {
    usluge: [],
    predstavnici: [],
    funkcije: [],
    keywords: [],
    globalKeywords: [],
    defineKeywords: false,
    details: {},
    odDatumaBaza: '2021-10-14',
    doDatumaBaza: '2021-10-16',
    email: 'iva.svesko@turizaminfo.hr',
    upisiNovuUsluguForma: false
  };

  componentDidMount() {
    //this.auth();
    this.refreshUsluge();
    //this.refreshFunkcije();
    //this.getGlobalKeywords();
    this.ucitajIzlagaca();
    //this.getKeywords();
  }

  ucitajIzlagaca = async () => {
    let { izlagac } = this.props.match.params;
    //console.log(izlagac);
    let url = Config.apiEndpoint + "/event/izlagac/";
    const response = await axios.post(
      url,
      {
        izlagac: izlagac,
      },
      {
        headers: {
          authorization: Cookies.get("token"),
          eventid: 102,
        },
      }
    );
    console.log(response);
    this.setState({
      details: response.data,
    });
  };

  /**
   * Keywordsi još nisu u funkciji, dok ih detaljno ne definiramo.
   */

  getGlobalKeywords = async () => {
    const organizator = 1;
    const url = Config.apiEndpoint + "/keywords/list";
    const response = await axios.get(url, {
      headers: {
        organizator: organizator,
      },
    });
    this.setState({ globalKeywords: response.data });
  };

  getKeywords = () => {
    const organizator = 1;
    const url = Config.apiEndpoint + "/event/izlagac/keywords/list";
    const response = axios.get(url, {
      headers: {
        organizator: organizator,
      },
    });
    this.setState({ keywords: response.data });
  };

  refreshUsluge = async () => {
    const token = Cookies.get("token");
    const url = Config.apiEndpoint + "/izlagac/usluge/list";
    const response = await axios.get(url, {
      headers: {
        izlagac: this.props.match.params.izlagac,
        authorization: Cookies.get("token"),
      },
    });
    console.log(response.data);
    this.setState({
      usluge: response.data,
    });
  };

  refreshFunkcije = () => {
    const organizator = 1;
    const izlagac = this.props.match.params.izlagac;
    const event = this.props.match.params.id;
    const url = Config.apiEndpoint + "/izlagac/funkcija/list";
    const response = axios.get(url, {
      headers: {
        organizator: organizator,
        izlagac: izlagac,
        event: event,
      },
    });
    this.setState({ funkcije: response.data });
  };

  auth = async () => {
    const token = Cookies.get("token");

    if (token) {
      const response = await axios.get(Config.apiEndpoint + "/check-token", {
        headers: {
          token: token,
          active_user: jwt.decode(token).id,
        },
      });
      if (response.data.response !== "Check passed") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  };

  posaljiIzvjestaj = async () => {
    const url = Config.apiEndpoint + "/izvjestaj-cijeli-izlagac"
    const response = await axios.post(url, {
      odDatumaBaza: this.state.odDatumaBaza,
      doDatumaBaza: this.state.doDatumaBaza,
      izlagac: this.props.match.params.izlagac,
      email: this.state.email
    });
  }

  onUpisiNovuUsluguIzlagaca = async (usluga, izlagac) => {
    const url = Config.apiEndpoint + "/izlagac/usluge/upis";
    const response = await axios.post(url, {
      usluga: usluga,
      izlagac: izlagac
    },
    { headers: {
      authorization: Cookies.get("token"),
    }})

    this.setState({ novaUsluga: '' })
    this.refreshUsluge();
    
  }

  onIzbrisiUsluguIzlagaca = async (usluga, izlagac) => {
    const url = Config.apiEndpoint + "/izlagac/usluge/delete";
    const response = await axios.get(url, {
      headers: {
      authorization: Cookies.get("token"),
      id: usluga,
      izlagac: izlagac
      }});
    this.refreshUsluge();
  }

  render() {
    return (
      <div>
        {/* <h3>Izlagac - ID: {this.props.match.params.izlagac}</h3> */}
        <div className='font-weight-bold'>
          Detalji o izlagaču:
        </div>
        <div style={{ fontSize: "20px", fontWeight: "700" }}>
          {this.state.details.naziv}
        </div>
    <div>Adresa: {this.state.details.adresa}, {this.state.details.grad_mjesto}</div>
        <div>Telefon: {this.state.details.telefon}</div>
        <div>Web: {this.state.details.web_adresa}</div>
        <div>E-mail: {this.state.details.email}</div>
        <div>Broj štanda: {this.state.details.broj_standa}</div>
        <div className='m-3'>
          Opis izlagača:
          <div>{this.state.details.opis}</div>
        </div>
        {/** Keywordsi za sada ne idu.
        
        
        <div className="container">
          <div className="row">
            <div className="col" />
            <div className="col text-left">
              <h4 className="font-weight-bold">Keywords: </h4>
              {this.state.defineKeywords ? (
                <div>
                  <ul>
                    {this.state.globalKeywords.map(keyword => (
                      <li>
                        <input type="checkbox" />
                        {keyword.keyword}
                      </li>
                    ))}
                  </ul>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.setState({ defineKeywords: false })}
                  >
                    Spremi
                  </button>
                </div>
              ) : (
                <div>
                  <ul>
                    {this.state.keywords.map(keyword => (
                      <li>{keyword.keyword}</li>
                    ))}
                  </ul>
                  <button
                    className="btn btn-success"
                    onClick={() => this.setState({ defineKeywords: true })}
                  >
                    Definiraj keywordse
                  </button>
                </div>
              )}
            </div>
            <div className="col" />
          </div>
        </div> */}
        <div>
          <div
            className='izlagac-details-kategorije-naslov mt-5'
            style={{ textDecoration: "underline" }}
          >
            Usluge izlagača
          </div>
          {this.state.usluge.length === 0 ? (
            <div className='izlagac-details-kategorije-tekst'>
              Nema upisanih usluga
            </div>
          ) : (
            <div className='izlagac-details-kategorije-tekst'>
              <ul style={{ paddingInlineStart: 0 }}>
                {this.state.usluge.map((usluga) => (
                  <li key={usluga.id} className='text-center'>
                    {usluga.usluga} 
                    <svg onClick={() => this.onIzbrisiUsluguIzlagaca(usluga.id, this.state.details.id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!this.state.upisiNovuUsluguForma && (
            <div>
              <label>Upiši novu uslugu</label>
              <input type="text" value={this.state.novaUsluga} onChange={event => this.setState({ novaUsluga: event.target.value })} />
              <button className="btn btn-primary ml-3 mr-3" onClick={event => this.onUpisiNovuUsluguIzlagaca(this.state.novaUsluga, this.state.details.id )}>Upiši</button>
            </div>
          )}
          {/* <div>
            <Link
              to={`/event/izlagac/kategorija/upis/${izlagac}/${this.props.match.params.id}`}
            >
              <button
                className="btn btn-secondary"
                disabled={this.state.usluge.length > 7}
              >
                Dodaj uslugu
              </button>
            </Link>
          </div> */}
        </div>
        <IzlagacPredstavnici izlagac={this.props.match.params.izlagac} />
        <IzlagacGosti izlagac={this.props.match.params.izlagac}/>
        <div style={{ backgroundColor: 'lightgrey', width: '1000px', margin: '0px auto', marginTop: '50px', marginBottom: '50px' }}>
          <h5>Slanje izvještaja za cijelog dobavljača za sajam HoReCa Adria 2021</h5>
          <div>
            <input type="text" name="odDatumaBaza" value={this.state.odDatumaBaza} onChange={event => this.setState({ odDatumaBaza: event.target.value })} />
            <input type="text" name="doDatumaBaza" value={this.state.doDatumaBaza} onChange={event => this.setState({ doDatumaBaza: event.target.value })} />
          </div>
          <div><input type="text" name="email" value={this.state.email} onChange={event => this.setState({ email: event.target.value })} style={{ width: '200px' }}/></div>
          <div><button onClick={this.posaljiIzvjestaj}>Pošalji</button></div>
        </div>
        {/** Za sada zakomentirano
         <div>
          <div className="izlagac-details-kategorije-naslov mt-5">
            Predstavnici izlagača - funkcije
          </div>
          {this.state.predstavnici.length === 0 ? (
            <div className="izlagac-details-kategorije-tekst">
              Nema postavljenih predstavnik - funkcija
            </div>
          ) : (
            <ul>
              {this.state.funkcije.map(funkcija => (
                <li>{funkcija.naziv}</li>
              ))}
            </ul>
          )}
          <div>
            <Link
              to={`/event/izlagac/funkcija/upis/${izlagac}/${this.props.match.params.id}`}
            >
              <button className="btn btn-secondary">Dodaj funkciju</button>
            </Link>
          </div>
          <div>Broj štanda:</div>
          <div>Max user:</div>
          <div>Max servisa:</div>
          <div>Send e-mail:</div>
        </div> */}
      </div>
    );
  }
}

export default IzlagacDetalji;
