import React, { useState, useEffect } from "react";

import axios from "axios";
import Config from "../../config.json";

import { useSelector } from "react-redux";

const ScanUlaz = () => {
  const [data, setData] = useState([]);
  const [barcode, setBarcode] = useState("");
  const token = useSelector(state => state.token);

  const styles = {
    naslov: {
      fontSize: "25px",
      fontWeight: 700
    },
    input: {
      width: "300px",
      marginBottom: "30px"
    }
  };

  useEffect(() => {
    ucitajPodatke();
  }, []);

  const handleKeyUp = event => {
    if (event.key === "Enter") {
      upis();
    }
  };

  const handleChange = event => {
    setBarcode(event.target.value);
  };

  const ucitajPodatke = async () => {
    let url = Config.apiEndpoint + "/scan/ulaz/podaci/";
    const response = await axios.get(url, {
      headers: {
        event: 102,
        authorization: token
      }
    });
    setData(response.data);
  };

  const upis = async () => {
    let url = Config.apiEndpoint + "/scan/ulaz/upis";
    const response = await axios.post(url, {
      event: 102,
      osoba: barcode
    }, {
      headers: {
        authorization: token
      }
    });
    setBarcode("");
    response && ucitajPodatke();
  };

  return (
    <div>
      <div style={styles.naslov}>Scan na ulazu</div>
      <div>
        <input
          type="text"
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          value={barcode}
          autoFocus
          style={styles.input}
        />
      </div>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="naslov-popis">
                Ime
              </th>
              <th scope="col" className="naslov-popis">
                Prezime
              </th>
              <th scope="col" className="naslov-popis">
                Tvrtka
              </th>
              <th scope="col" className="naslov-popis">
                Funkcija
              </th>
              <th scope="col" className="naslov-popis">
                Vrijeme
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((posjetitelj, index) => (
              <tr key={index}>
                <td className="tekst-event-scanulaz">{posjetitelj.ime}</td>
                <td className="tekst-event-scanulaz">{posjetitelj.prezime}</td>
                <td className="tekst-event-scanulaz">{posjetitelj.tvrtka}</td>
                <td className="tekst-event-scanulaz">{posjetitelj.funkcija}</td>
                <td className="tekst-event-scanulaz">{posjetitelj.vrijeme}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScanUlaz;
