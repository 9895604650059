import React from "react";

const Resources = () => {
  return (
    <div>
      <div>Resources</div>
    </div>
  );
};

export default Resources;
