import React, { Component } from "react";

import axios from "axios";
import Config from "../../config.json";

class UpisAdmina extends Component {
  state = {
    ime: "",
    prezime: "",
    username: "",
    password: ""
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const organizator = 1;
    const url = Config.apiEndpoint + "/event" + event + "/admin/create";
    const response = await axios.post(url, {
      organizator: organizator,
      data: this.state
    });
    console.log(response);
    this.props.history.push(`/event/admins/${this.props.match.params.id}`);
  };
  render() {
    return (
      <React.Fragment>
        <h4>Postavke o organizatoru - upis administratora</h4>
        <form onSubmit={this.handleSubmit}>
          <div class="form-group">
            <label for="ime">Ime: </label>
            <input
              type="text"
              class="form-control"
              id="ime"
              aria-describedby="emailHelp"
              onChange={this.handleChange}
              autoFocus
            />
          </div>
          <div class="form-group">
            <label for="prezime">Prezime: </label>
            <input
              type="text"
              class="form-control"
              id="prezime"
              onChange={this.handleChange}
            />
          </div>
          <div class="form-group">
            <label for="username">Username: </label>
            <input
              type="text"
              class="form-control"
              id="username"
              onChange={this.handleChange}
            />
          </div>
          <div class="form-group">
            <label for="password">Password: </label>
            <input
              type="password"
              class="form-control"
              id="password"
              onChange={this.handleChange}
            />
          </div>
          <button type="submit" class="btn btn-primary">
            Spremi
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default UpisAdmina;
