export const upisTokena = token => {
  return {
    type: "UPIS_TOKENA",
    payload: token
  };
};

export const upisUsername = username => {
  return {
    type: "UPIS_USERNAME",
    payload: username
  };
};

export const upisActiveEventa = event => {
  return {
    type: "UPIS_ACTIVE_EVENTA",
    payload: event
  };
};

export const upisOsobe = osoba => {
  return {
    type: "UPIS_OSOBE",
    payload: osoba
  };
};
