import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import React from "react";
import { Redirect } from "react-router-dom";
import AdminBox from "./AdminBox";
import "./App.css";
import EventNavBar from "./Components/Event/EventNavBar";
import LoggedUserBox from "./LoggedUserBox";

const LeftNavBar = () => {
  const token = jwt.decode(Cookies.get("token"));

  return (
    <nav className="col-3 align-top">
      <ul className="nav flex-column">
        <li className="nav-item mb-5 pl-3" />
        {token && token.username === "tturak" && <AdminBox />}
        {token && <LoggedUserBox token={token} />}
        {!token ? (
          <Redirect to="/login" />
        ) : (
          <EventNavBar id_eventa={token.active_event} />
        )}
      </ul>
    </nav>
  );
};

export default LeftNavBar;
