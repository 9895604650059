import React, { Component } from "react";

import logo from "../images/mailchimp.svg";

class Signup extends Component {
  state = {};

  componentDidMount() {
    document.body.style.backgroundColor = "#FFFFFF";
    document.getElementById("globalNavBar").style.display = "none";
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3">
            <a href="/">
              <img
                src={logo}
                alt="Logo"
                style={{ width: "200px", height: "210px", marginTop: "36px" }}
              />
            </a>
          </div>
          <div className="col-6">
            <h1
              className="text-left title-font"
              style={{
                fontSize: "3rem",
                lineHeight: "1.1",
                marginBottom: "18px",
                wordSpacing: "-0.2rem"
              }}
            >
              Get started with your account
            </h1>
            <div
              className="text-left default-font"
              style={{
                color: "#241c15",
                marginBottom: "30px",
                lineHeight: "24px",
                fontSize: "17px"
              }}
            >
              Find your people. Engage your customers. Build your brand. Do it
              all with Mailchimp’s Marketing Platform. Already have an account?
            </div>
            <form className="text-left mt-4">
              <div className="form-group text-left font-weight-bold">
                <label htmlFor="inputEmail" className="default-font">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control signup-form-input"
                  id="inputEmail"
                  aria-describedby="emailField"
                />
              </div>
              <div className="form-group text-left font-weight-bold">
                <label htmlFor="inputUsername" className="default-font">
                  Username
                </label>
                <input
                  type="email"
                  className="form-control signup-form-input"
                  id="inputUsername"
                  aria-describedby="usernameField"
                />
              </div>
              <div className="form-group text-left font-weight-bold">
                <label htmlFor="inputPassword" className="default-font">
                  Password
                </label>
                <div className="float-right">Show</div>
                <input
                  type="password"
                  className="form-control signup-form-input"
                  id="inputPassword"
                />
              </div>
              <button type="submit" className="btn btn-primary default-font">
                Submit
              </button>
            </form>
            <div className="container">
              <div className="row">
                <div className="col-6 text-left">
                  <ul class="small-meta selfclear">
                    <li class="lowercase-char">One lowercase character</li>
                    <li class="uppercase-char">One uppercase character</li>
                    <li class="number-char">One number</li>
                  </ul>
                </div>
                <div className="col-6 text-left">
                  <ul>
                    <li class="special-char">One special character</li>
                    <li class="8-char">8 characters minimum</li>
                    <li class="plus-50 error">50 characters maximum</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
