import React from "react";

const WhyE2Blink = () => {
  return (
    <div>
      <div>WhyE2Blink ?</div>
    </div>
  );
};

export default WhyE2Blink;
