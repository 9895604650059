import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../../config.json";
import "./UpisAdmina.css";

class PopisAdmina extends Component {
  state = {
    popis: []
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const event = this.props.match.params.event;
    const organizator = 1;
    const url = Config.apiEndpoint + "/event" + event + "/admins/list";
    const response = await axios.get(url, {
      headers: {
        organizator: organizator
      }
    });
    this.setState({
      popis: response.data
    });
  };

  /**
   * Provjeriti što točno radi ovaj kod...
   */

  handleDelete = async id => {
    const organizator = 1;
    const url = Config.apiEndpoint + "/event/100/admin";
    const response = axios.delete(url, {
      headers: {
        organizator: organizator,
        id: id
      }
    });
    console.log(response);

    fetch(`${Config.apiEndpoint}/event/100/admin/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        organizator: 1,
        id: id
      }
    })
      .then(res => res.json())
      .then(res => {
        const _popis = [...this.state.popis];
        const __popis = _popis.filter(admin => admin.id !== id);
        this.setState({ popis: __popis });
        this.props.history.push(
          `/event/${this.props.match.params.event}/admin`
        );
      });
  };

  render() {
    const id_eventa = this.props.match.params.event;
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h3>Organizator - popis administratora</h3>
              <div className="upis-admina">
                <Link to={`/event/${id_eventa}/admin/create`}>
                  <button className="btn btn-primary mb-2">
                    Upis administratora
                  </button>
                </Link>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Ime</th>
                    <th>Prezime</th>
                    <th>Aktivan</th>
                    <th>Izmjena</th>
                    <th>Brisanje</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.popis.map(admin => (
                    <tr>
                      <td>{admin.ime}</td>
                      <td>{admin.prezime}</td>
                      <td>
                        <input
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                        />
                      </td>
                      <td>
                        <button className="btn btn-secondary">Izmjeni</button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => this.handleDelete(admin.id)}
                        >
                          Izbriši
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h3>Plaćanja</h3>
              <Link to="/event/upis">
                <button className="btn btn-success">Kreiraj novi event</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopisAdmina;
