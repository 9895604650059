import React from "react";

import "./modal.css";
import { tsPropertySignature } from "@babel/types";

export const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const modal = "modal display-block";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">{children}</section>
    </div>
  );
};
