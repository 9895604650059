import React from "react";

const Pricing = () => {
  return (
    <div>
      <div>Pricing</div>
    </div>
  );
};

export default Pricing;
