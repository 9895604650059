const initialState = {
  token: "",
  username: "",
  active_event: "",
  osoba: {}
};

export function xpocrmReducer(state = initialState, action) {
  switch (action.type) {
    case "UPIS_TOKENA":
      return {
        ...state,
        token: action.payload
      };
    case "UPIS_USERNAME":
      return {
        ...state,
        username: action.payload
      };
    case "UPIS_ACTIVE_EVENT":
      return {
        ...state,
        active_event: action.payload
      };
    case "UPIS_OSOBE":
      return {
        ...state,
        osoba: action.payload
      };
    default:
      return state;
  }
}
