import React from "react";

const CheckListItem = props => {
  return (
    <React.Fragment>
      <input type="checkbox" value={props.value} checked={props.checked} />
      {props.value}
      <br />
    </React.Fragment>
  );
};

export default CheckListItem;
