import React, { Component } from "react";

import { Link, NavLink, Redirect } from "react-router-dom";
import EventName from "./EventName";
import Cookies from "js-cookie";
import axios from "axios";
import Config from "../../config.json";
import "./EventNavBar.css";

class EventNavBar extends Component {
  state = {
    id_eventa: "",
    naziv_eventa: ""
  };

  componentDidMount() {
    //this.getDetaljiEventa();
  }

  getDetaljiEventa = async () => {
    const event = this.props.id.id_eventa.id;
    const url = Config.apiEndpoint + "/event/get-detalji-eventa";
    const response = await axios.get(url, {
      headers: {
        id: event
      }
    });
    this.setState({
      id_eventa: event,
      naziv_eventa: response.data[0].naziv
    });
  };

  handleLogout = async () => {
    const url = Config.apiEndpoint + "/event/logout";
    const response = await axios.post(url, {
      organizator: 1
    });
    console.log(response);
    Cookies.remove("active_event");
    return <Redirect to="/event" />;
  };

  render() {
    if (!Cookies.get("token")) {
      return false;
    }
    const { id_eventa } = this.props;
    const activeStyle = {
      backgroundColor: "orange",
      color: "white",
      padding: "7px",
      borderRadius: "4px"
    };
    return (
      <nav className="mt-4">
        <ul className="nav flex-column">
          <li className="nav-item">
            <span>
              <EventName id={id_eventa} />
            </span>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/event/${id_eventa}/details`}
              activeStyle={activeStyle}
            >
              - Postavke
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/event/${id_eventa}/djelatnosti`}
              activeStyle={activeStyle}
            >
              - Djelatnosti
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/event/${id_eventa}/categories`}
              activeStyle={activeStyle}
            >
              - Kategorije
            </NavLink>
          </li>
          <br />
          <li className="nav-item">
            <NavLink
              to={`/event/${id_eventa}/izlagaci/list`}
              activeStyle={activeStyle}
            >
              Izlagači
            </NavLink>
          </li>
          <br />
          <li className="nav-item">
            <NavLink to={`/event/${id_eventa}/osobe`} activeStyle={activeStyle}>
              Sudionici
            </NavLink>
          </li>
          <br />
          <li className="nav-item">
            <NavLink to={`/event/${id_eventa}/admin`} activeStyle={activeStyle}>
              Organizator
            </NavLink>
          </li>
          <br />
          <li className="nav-item">
            <Link to="/event" onClick={() => this.handleLogout()}>
              Log Out
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default EventNavBar;
