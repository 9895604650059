import React, { Component } from "react";

import Config from "../../config.json";

class UpisKategorije extends Component {
  state = { naziv: "" };

  handleChange = event => {
    this.setState({
      naziv: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const url =
      Config.apiEndpoint +
      "/event" +
      this.props.match.params.event +
      "/categories/create";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        event_id: this.props.match.params.event,
        naziv: this.state.naziv
      })
    })
      .then(res => {
        console.table(res);
        this.props.history.push(
          `/event/${this.props.match.params.event}/categories/`
        );
      })
      .catch(err => {
        console.log(err);
      });

    console.log("Upis...");
  };

  render() {
    return (
      <React.Fragment>
        <h4>Upis nove kategorije</h4>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="naziv">Naziv kategorije</label>
          <input
            type="text"
            name="naziv"
            onChange={this.handleChange}
            autoFocus
          />
          <button className="btn btn-primary">Save</button>
        </form>
      </React.Fragment>
    );
  }
}

export default UpisKategorije;
