import React, { Component } from "react";

import axios from "axios";
import Config from "../../config.json";

class EventName extends Component {
  state = {
    naziv: ""
  };

  componentDidMount() {
    this.props.id && this.getEventDetails();
  }

  getEventDetails = async () => {
    const url = Config.apiEndpoint + "/event/100/details";
    const response = await axios.get(url);
    this.setState({ naziv: response.data.naziv });
  };

  render() {
    return <span>{this.state.naziv}</span>;
  }
}

export default EventName;
