import React from "react";

import { NavLink } from "react-router-dom";

import config from "./config.json";

const AdminBox = () => {
  return (
    <React.Fragment>
      <li className="nav-item">
        <NavLink to="/keywords" exact activeStyle={config.activeStyle}>
          Globalni keywordovi
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/organizator" activeStyle={config.activeStyle}>
          Popis organizatora
        </NavLink>
      </li>
    </React.Fragment>
  );
};

export default AdminBox;
