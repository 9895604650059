import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../../config.json";

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      organizator: "",
      naziv: "",
      info: "",
      logo: "",
      datumod: "",
      datumdo: "",
      adresa: "",
      startDate: new Date(),
      globalKeywords: []
    };
  }

  componentDidMount() {
    this.getDetaljiEventa();
    this.getGlobalKeywords();
  }

  handleDateStartChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleDateEndChange = date => {
    this.setState({
      endDate: date
    });
  };

  getDetaljiEventa = async () => {
    const url = Config.apiEndpoint + "/event/100/details";
    const response = await axios.get(url);
    const {
      organizator,
      naziv,
      info,
      logo,
      datumod,
      datumdo,
      adresa
    } = response.data;
    this.setState({
      organizator,
      naziv,
      info,
      datumod,
      datumdo,
      adresa,
      logo
    });
  };

  getGlobalKeywords = async () => {
    const url = Config.apiEndpoint + "/keywords/list";
    const response = await axios.get(url, {
      headers: {
        organizator: 1
      }
    });
    this.setState({ globalKeywords: response.data });
  };

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h5 className="text-right">
                <Link
                  to={{ pathname: "/event/upis", state: { id: this.state.id } }}
                >
                  <button className="btn-secondary">Edit eventa</button>
                </Link>
              </h5>
              <h2>
                <strong>Detalji eventa</strong>
              </h2>
              <h3>Organizator: {this.state.organizator}</h3>
              <h4>Naziv eventa: {this.state.naziv}</h4>
              <h5>Osnovni info o eventu: {this.state.info} </h5>
              {this.state.logo && (
                <div>
                  <img
                    src={`data:image/png;base64,` + this.state.logo}
                    id="logo"
                    style={{ width: "200px" }}
                    alt="Logo"
                  />
                </div>
              )}

              <div>
                <h6>Datum početka održavanja eventa: {this.state.datumod}</h6>
                <h6>Datum završetka eventa: {this.state.datumdo}</h6>
              </div>
              <div>Adresa eventa: {this.state.adresa}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventDetails;
