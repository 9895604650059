import React, { Component } from "react";

import axios from "axios";
import Config from "../../config.json";

class IzlagacUpisKategorije extends Component {
  state = { naziv: "" };

  handleChange = event => {
    this.setState({
      naziv: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const event_id = this.props.match.params.id;
    const izlagac = this.props.match.params.izlagac;
    const naziv = this.state.naziv;
    const url = Config.apiEndpoint + "/izlagac/kategorija/upis";
    const response = await axios.post(url, {
      event_id: event_id,
      izlagac: izlagac,
      naziv: naziv
    });
    console.log(response);
    this.props.history.push(`/event/izlagac/${izlagac}/${event}`);
  };

  render() {
    return (
      <React.Fragment>
        <h4>Upis nove kategorije</h4>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="naziv">Naziv kategorije</label>
          <input
            type="text"
            name="naziv"
            onChange={this.handleChange}
            autoFocus
          />
          <button className="btn btn-primary">Save</button>
        </form>
      </React.Fragment>
    );
  }
}

export default IzlagacUpisKategorije;
