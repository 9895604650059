import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "./Modal";
import UpisOsobe from "./UpisOsobe";

import { useSelector } from "react-redux";

import Config from "../../config.json";
import jwt from "jsonwebtoken";
import Cookies from "js-cookie";

const EventOsobe = (props) => {
  const data = useSelector((state) => state);

  const [popis, setPopis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [osoba, setOsoba] = useState({});
  const [button, setButton] = useState("");
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    ucitajOsobe();
  }, []);

  const handleChange = (event) => {
    setBarcode(event.target.value);
  };

  const ucitajOsobe = async () => {
    setLoading(true);
    const url = Config.apiEndpoint + "/event/osobe/list";
    const response = await axios.get(url, {
      headers: {
        authorization: Cookies.get("token"),
        eventid: 104,
      },
    });
    console.log(response);
    setPopis(response.data);
    setLoading(false);
  };

  const ucitajOsobu = async () => {
    if (!barcode) {
      alert("Niste upisali barkod ili sigurnosni kod.");
      return false;
    }
    const url = Config.apiEndpoint + "/event/visitor/from-barcode";

    let barcode_velika_slova = barcode.toUpperCase();
    const response = await axios.get(url, {
      headers: {
        barcode: barcode_velika_slova,
      },
      mode: "cors",
    });
    setBarcode("");
    if (!response.data.length) {
      alert(
        "Posjetitelj sa upisanim barkodom/sigurnosnim kodom ne postoji, molimo pokušajte ponovno."
      );
      return false;
    }
    setOsoba(response.data[0]);
    setButton("Ažuriraj");
    setEdit(true);
    setShowModal(true);
  };

  return (
    <div>
      <div className='screen'>
        <div className='row'>
          <Modal show={showModal} osoba={osoba}>
            <p
              style={{
                fontSize: 20,
                fontWeight: 700,
                marginTop: 5,
                marginBottom: 5,
                border: "1px solid blue",
                borderRadius: 5,
              }}
            >
              Podaci o posjetitelju
            </p>
            <UpisOsobe osoba={osoba} edit={edit} />
            {/* <button class="ml-4" onClick={() => printAkreditacije()}>
              Ispis na zebru
            </button> */}
            <button className='sm-4' onClick={() => setShowModal(false)}>
              Zatvori
            </button>
          </Modal>
          <div className='col-11'>
          <div>Ukupno registrirano (putem web formulara): {popis.length}</div>
            <h2>Sudionici - posjetitelji</h2>

            {loading && (
              <div
                className='text-center'
                style={{ fontSize: 30, color: "#FF0000" }}
              >
                Učitavam...
              </div>
            )}
            <table className='table ml-5'>
              <thead>
                <tr>
                  <th>Ime</th>
                  <th>Prezime</th>
                  <th>Tvrtka</th>
                  <th>FriendlyID</th>
                  <th>Funkcija</th>
                  <th>Djelatnost</th>
                  {/* <th>E-mail</th>
                  <th>Telefon</th>
                  <th>Mobitel</th>
                  <th>Adresa</th>
                  <th>Poštanski broj</th> */}
                  <th>Grad</th>
                  <th>Država</th>
                  <th>Vrsta registracije</th>
                  <th>Kategorija</th>
                  <th>Kreirano</th>
                </tr>
              </thead>
              <tbody>
                {popis.map((osoba) => (
                  <tr key={osoba.id}>
                    <td className='tekst-event-osobe text-center'>{osoba.ime}</td>
                    <td className='tekst-event-osobe text-center'>{osoba.prezime}</td>
                    <td className='tekst-event-osobe text-center'>{osoba.tvrtka}</td>
                    <td className='tekst-event-osobe text-center'>{osoba.friendlyid}</td>
                    <td className='tekst-event-osobe text-center'>{osoba.funkcija}</td>
                    <td className='tekst-event-osobe text-center'>{osoba.djelatnost}</td>
                    {/* <td className='tekst-event-osobe'>{osoba.email}</td>
                    <td className='tekst-event-osobe'>{osoba.telefon}</td>
                    <td className='tekst-event-osobe'>{osoba.mobitel}</td>
                    <td className='tekst-event-osobe'>{osoba.adresa}</td>
                    <td className='tekst-event-osobe'>
                    {osoba.postanski_broj}
                  </td> */}
                    <td className='tekst-event-osobe text-center'>{osoba.grad_mjesto}</td>
                    <td className='tekst-event-osobe text-center'>{osoba.drzava}</td>
                    <td className='tekst-event-osobe text-center'>
                      {osoba.vrsta_registracije}
                    </td>
                    <td className='tekst-event-osobe text-center'>{osoba.kategorija}</td>
                    <td className='tekst-event-osobe text-center'>{osoba.vrijeme_prijave}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventOsobe;
