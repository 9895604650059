import React from "react";

import { NavLink } from "react-router-dom";

const LoggedUserBox = props => {
  return (
    <React.Fragment>
      <li className="nav-item my-2">
        <NavLink to="/organizator" style={{ fontSize: "12px", color: "grey" }}>
          Prijavljeni ste kao korisnik: {props.token.username}
        </NavLink>
      </li>
      {/*
      <li className="nav-item mt-5">
        <NavLink to="/event" activeStyle={activeStyle}>
          Popis evenata
        </NavLink>
      </li>
      */}
      <li className="nav-item mb-2">
        <NavLink to="/logout">Logout</NavLink>
      </li>
    </React.Fragment>
  );
};

export default LoggedUserBox;
