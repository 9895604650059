import React, { useState, useEffect } from "react";

import axios from "axios";
import Config from "../../config.json";

import Cookies from 'js-cookie';

const IzlagacGosti = props => {
  const [popis, setPopis] = useState([]);

  const [upisiNovogForma, setUpisiNovogForma] = useState(false);

  const [oslovljavanje, setOslovljavanje] = useState("");
  const [ime, setIme] = useState("");
  const [prezime, setPrezime] = useState("");
  const [tvrtka, setTvrtka] = useState("");
  const [funkcija, setFunkcija] = useState("");
  const [email, setEmail] = useState("");
  const [mobitel, setMobitel] = useState("");
  const [djelatnost, setDjelatnost] = useState("");

  useEffect(() => {
    ucitajGoste();
  }, []);

  const ucitajGoste = async () => {
    let url = Config.apiEndpoint + "/event/izlagac/ucitaj-goste";
    const response = await axios.get(url, {
      headers: {
        izlagac: props.izlagac,
        authorization: Cookies.get("token")
      }
    });
    setPopis(response.data);
    console.log("Gosti", response.data);
    console.log("Izlagac", props.izlagac);
  };

  const snimi = async event => {
    event.preventDefault();

    //const dupliEmail = popis.filter(predstavnik => predstavnik.email === email);
    //console.log(dupliEmail.length);

    let url = Config.apiEndpoint + "/event/izlagac/dodaj-gosta";
    const response = await axios.post(url, {
      oslovljavanje: oslovljavanje,
      ime: ime,
      prezime: prezime,
      funkcija: funkcija,
      email: email,
      mobitel: mobitel,
      djelatnost: djelatnost,
      izlagac: props.izlagac
    }, {
      headers: {
        authorization: Cookies.get("token")
      }
    });

    console.log("INSERT ID: ", response.data.insertId);

    ucitajGoste();

    const insertId = response.data;

    setOslovljavanje("");
    setIme("");
    setPrezime("");
    setFunkcija("");
    setEmail("");
    setMobitel("");
    setDjelatnost("");

    setUpisiNovogForma(false);
  };

  return (
    <div
      className="mt-5"
      style={{ backgroundColor: "#ede9e8", padding: "20px" }}
    >
      <div style={{ fontSize: "16px", fontWeight: "700" }}>Gosti izlagača</div>
      <div className="text-right mb-4">
        <button
          className="btn-secondary"
          onClick={() => setUpisiNovogForma(!upisiNovogForma)}
        >
          Upiši novog
        </button>
      </div>
      {upisiNovogForma && (
        <form onSubmit={snimi}>
          <div className="form-group row">
            <label htmlFor="oslovljavanje" className="col-sm-6 text-right">
              Oslovljavanje
            </label>
            <div className="col-sm-6 text-left">
              <select
                name="oslovljavanje"
                value={oslovljavanje}
                onChange={event => setOslovljavanje(event.target.value)}
              >
                <option value="">-</option>
                <option value="g.">g.</option>
                <option value="gđa">gđa</option>
                <option value="gđica">gđica</option>
              </select>
            </div>
            <label htmlFor="ime" className="col-sm-6 text-right">
              Ime
            </label>
            <div className="col-sm-6 text-left">
              <input
                type="text"
                name="ime"
                value={ime}
                onChange={event => setIme(event.target.value)}
              />
            </div>
            <label htmlFor="prezime" className="col-sm-6 text-right">
              Prezime
            </label>
            <div className="col-sm-6 text-left">
              <input
                type="text"
                name="prezime"
                value={prezime}
                onChange={event => setPrezime(event.target.value)}
              />
            </div>
            <label htmlFor="tvrtka" className="col-sm-6 text-right">
              Tvrtka
            </label>
            <div className="col-sm-6 text-left">
              <input
                type="text"
                name="tvrtka"
                value={tvrtka || props.tvrtka}
                onChange={event => setTvrtka(event.target.value)}
              />
            </div>
            <label htmlFor="funkcija" className="col-sm-6 text-right">
              Funkcija
            </label>
            <div className="col-sm-6 text-left">
              <input
                type="text"
                name="funkcija"
                value={funkcija || "Gost"}
                onChange={event => setFunkcija(event.target.value)}
              />
            </div>
            <label htmlFor="email" className="col-sm-6 text-right">
              E-mail
            </label>
            <div className="col-sm-6 text-left">
              <input
                type="text"
                name="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </div>
            <label htmlFor="mobitel" className="col-sm-6 text-right">
              Mobitel
            </label>
            <div className="col-sm-6 text-left">
              <input
                type="text"
                name="mobitel"
                value={mobitel}
                onChange={event => setMobitel(event.target.value)}
              />
            </div>
            <label htmlFor="djelatnost" className="col-sm-6 text-right">
              Djelatnost
            </label>
            <div className="col-sm-6 text-left">
              <input
                type="text"
                name="djelatnost"
                value={djelatnost || "Ostalo"}
                onChange={event => setDjelatnost(event.target.value)}
              />
            </div>
            <div className="col-sm-12 text-center">
              <button className="btn btn-success">Snimi</button>
            </div>
          </div>
        </form>
      )}
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ textAlign: "left", fontSize: '20px' }}>
              Ime
            </th>
            <th scope="col" style={{ textAlign: "left", fontSize: '20px' }}>
              Prezime
            </th>
            <th scope="col" style={{ textAlign: "left", fontSize: '20px' }}>
              Tvrtka
            </th>
            <th scope="col" style={{ textAlign: "left", fontSize: '20px' }}>
              Mjesto
            </th>
            <th scope="col" style={{ textAlign: "left", fontSize: '20px' }}>
              E-mail
            </th>
            <th scope="col" style={{ textAlign: "left", fontSize: '20px' }}>
              Telefon
            </th>
            <th scope="col" style={{ textAlign: "left", fontSize: '20px' }}>
              Mobitel
            </th>
          </tr>
        </thead>
        <tbody>
          {popis.map(gost =>
          <tr>
            <td>{gost.ime}</td>
            <td>{gost.prezime}</td>
            <td>{gost.tvrtka}</td>
            <td>{gost.grad_mjesto}</td>
            <td>{gost.email}</td>
            <td>{gost.telefon}</td>
            <td>{gost.mobitel}</td>
            <td>-</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default IzlagacGosti;
