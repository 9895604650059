import React, { Component } from "react";

class EditOrganizatora extends Component {
  state = {
    organizator: {},
    inputNaziv: "",
    inputPassword: "",
    username: ""
  };

  componentDidMount() {
    fetch("http://localhost:8081/organizator/detalji", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        id: this.props.match.params.id
      }
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          organizator: res,
          inputNaziv: res.naziv,
          inputPassword: res.password,
          username: res.username
        });
        console.log("Podaci o organizatoru učitani.");
        console.log(res);
      })
      .catch(err => console.log(err));
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    console.log(
      "PODACI: " + this.props.match.params.id + this.state.inputNaziv
    );
    fetch("http://localhost:8081/organizator/edit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: this.props.match.params.id,
        naziv: this.state.inputNaziv,
        password: this.state.inputPassword
      })
    })
      .then(res => res.json())
      .then(res => this.props.history.push("/organizator"));
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        Naziv organizatora:{" "}
        <input
          type="text"
          value={this.state.inputNaziv}
          name="inputNaziv"
          onChange={this.handleChange}
        />
        <br />
        <p>Username: {this.state.username}</p>
        Novi password:{" "}
        <input
          type="password"
          name="inputPassword"
          value={this.state.inputPassword}
          onChange={this.handleChange}
        />
        <button className="btn btn-primary">Update</button>
      </form>
    );
  }
}

export default EditOrganizatora;
