import Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { upisTokena } from "./store/actions/xpocrm";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Authentication from "./Components/Authentication/Authentication";
import EventDetails from "./Components/Event/EventDetails";
import EventDjelatnosti from "./Components/Event/EventDjelatnosti";
import Izlagaci from "./Components/Event/Izlagaci";
import EventKategorije from "./Components/Event/EventKategorije";
import EventOsobe from "./Components/Event/EventOsobe";
import IzlagacDetalji from "./Components/Event/IzlagacDetalji";
import IzlagacUpisFunkcije from "./Components/Event/IzlagacUpisFunkcije";
import IzlagacUpisKategorije from "./Components/Event/IzlagacUpisKategorije";
import PopisAdmina from "./Components/Event/PopisAdmina";
import PopisEvenata from "./Components/Event/PopisEvenata";
import UpisAdmina from "./Components/Event/UpisAdmina";
import UpisDjelatnosti from "./Components/Event/UpisDjelatnosti";
import UpisEventa from "./Components/Event/UpisEventa";
import UpisKategorije from "./Components/Event/UpisKategorije";
import UpisOsobe from "./Components/Event/UpisOsobe";
import EditOrganizatora from "./Components/Organizator/EditOrganizatora";
import PopisOrganizatora from "./Components/Organizator/PopisOrganizatora";
import UpisOrganizatora from "./Components/Organizator/UpisOrganizatora";
import FrontPage from "./FrontPage/FrontPage";
import GlobalNavBar from "./FrontPage/GlobalNavBar";
import Marketing from "./FrontPage/Marketing";
import Pricing from "./FrontPage/Pricing";
import Resources from "./FrontPage/Resources";
import Signup from "./FrontPage/Signup";
import WhyE2Blink from "./FrontPage/WhyE2Blink";
import Homepage from "./Homepage";
import Keywords from "./Keywords";
import Logout from "./Logout";
import UserNavBar from "./UserNavBar";
import ScanUlaz from "./Components/Event/ScanUlaz";
import Sastanci from "./Components/Event/Sastanci";
import Pult from "./Components/Event/Pult";

function App() {
  const dispatch = useDispatch();
  const globalToken = useSelector((state) => state.token);
  const token = Cookies.get("token");

  if (!token) {
    //window.location.href = "/login";
  } else {
    dispatch(upisTokena(token));
  }

  return (
    <BrowserRouter>
      {globalToken ? <UserNavBar /> : <GlobalNavBar />}
      <div className='App'>
        <Switch>
          <Route path='/event/upis' component={UpisEventa} />
          <Route path='/organizator/upis' component={UpisOrganizatora} />
          <Route path='/organizator/edit/:id' component={EditOrganizatora} />
          <Route exact path='/organizator' component={PopisOrganizatora} />
          <Route path='/event/:id/details' component={EventDetails} />
          <Route
            exact
            path='/event/:event/admin/create'
            component={UpisAdmina}
          />
          <Route exact path='/event/:event/admin' component={PopisAdmina} />
          <Route exact path='/event/:id/persons/upis' component={UpisOsobe} />
          <Route exact path='/event/osobe/list' component={EventOsobe} />
          <Route
            path='/event/izlagac/kategorija/upis/:izlagac/:id'
            component={IzlagacUpisKategorije}
          />
          <Route
            path='/event/izlagac/funkcija/upis/:izlagac/:id'
            component={IzlagacUpisFunkcije}
          />
          <Route path='/event/izlagac/:izlagac' component={IzlagacDetalji} />
          <Route
            exact
            path='/event/izlagaci/details/:id'
            component={EventOsobe}
          />
          <Route
            exact
            path='/event/izlagaci/detalji/:id_izlagaca/:id'
            component={Izlagaci}
          />
          <Route
            exact
            path='/event/izlagaci/list'
            component={Izlagaci}
          />
          <Route
            exact
            path='/event/djelatnosti/upis/:id'
            component={UpisDjelatnosti}
          />
          <Route
            exact
            path='/event/:event/djelatnosti'
            component={EventDjelatnosti}
          />
          <Route
            exact
            path='/event/:event/categories/create'
            component={UpisKategorije}
          />
          <Route
            exact
            path='/event/:event/categories'
            component={EventKategorije}
          />
          <Route exact path='/event' component={PopisEvenata} />
          <Route path='/signup' component={Signup} />
          <Route path='/login' component={Authentication} />
          <Route path='/logout' component={Logout} />
          <Route path='/keywords' component={Keywords} />
          <Route exact path='/home' component={Homepage} />
          <Route path='/whye2blink' component={WhyE2Blink} />
          <Route path='/marketing' component={Marketing} />
          <Route path='/pricing' component={Pricing} />
          <Route path='/resources' component={Resources} />
          <Route path='/ulaz/scan' component={ScanUlaz} />
          <Route path='/pult' component={Pult} />
          <Route path='/event/sastanci' component={Sastanci} />
          <Route exact path='/' component={FrontPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
