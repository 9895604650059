import React, { useState } from "react";

import CheckListItem from "./CheckListItem";

const CheckList = props => {
  const [list, setList] = useState(props.list);

  return (
    <div>
      {list.map(listItem => (
        <CheckListItem value={listItem.value} checked={listItem.checked} />
      ))}
    </div>
  );
};

export default CheckList;
