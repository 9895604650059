import React, { Component } from "react";

import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import axios from "axios";
import Config from "../../config.json";
import "./PopisEvenata.css";

class Event extends Component {
  state = {
    popis: []
  };

  componentDidMount() {
    //this.getActiveEvent();
    this.refresh();
  }

  getActiveEvent = async () => {
    const organizator = jwt.decode(Cookies.get("token")).id;
    const url = Config.apiEndpoint + "/organizator/get-active-event";
    const response = await axios.get(url, {
      headers: {
        organizator: organizator
      }
    });
    //const active_event = response.data.active_event[0].active_event;
    //Cookies.set("event_id", active_event);
    //f (active_event) this.props.history.push(`/event/${active_event}/details`);
  };

  refresh = async () => {
    const organizator = jwt.decode(Cookies.get("token")).id;
    const url = Config.apiEndpoint + "/popis-evenata";
    const response = await axios.get(url, {
      headers: {
        organizator: organizator
      }
    });
    this.setState({ popis: response.data });
  };

  setActiveEvent = async id => {
    if (
      !window.confirm(
        "Kliknite OK za postavljanje drugog eventa, te automatsku odjavu.\nMorati ćete se ponovno prijaviti u sustav."
      )
    ) {
      return false;
    } else {
      const organizator = jwt.decode(Cookies.get("token")).id;
      const event = id;
      const token = Cookies.get("token");
      const url = Config.apiEndpoint + "/event/set-active-event";
      const response = await axios(url, {
        organizator: organizator,
        event: event,
        current_token: token
      });
      window.location.href = "/logout";

      /**
       * Provjeriti zašto ovo ide na logout?
       */
      /*
      fetch("http://localhost:8081/event/set-active-event", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          organizator: jwt.decode(Cookies.get("token")).id,
          event: event,
          current_token: Cookies.get("token")
        })
      })
        .then(res => res.json())
        .then(res => {
          //Cookies.set("active_event", id);
          //this.props.history.push(`/event/${id}/details`);
          window.location.href = "/logout";
        });
        */
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className='upis-eventa'>
          <Link to='/event/upis'>Upis novog eventa</Link>
        </div>

        <h3>Odaberi aktivni event: </h3>

        <table className='table'>
          <tbody>
            {this.state.popis.map(event => (
              <tr key={event.id} className='event-list-row'>
                <td onClick={() => this.setActiveEvent(event.id)}>
                  {event.naziv}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default Event;
