import Cookies from "js-cookie";
import React from "react";
import { Redirect } from "react-router-dom";
import Authentication from "../Components/Authentication/Authentication";

const FrontPage = () => {
  return Cookies.get("token") ? <Redirect to='/' /> : <Authentication />;
};

export default FrontPage;
