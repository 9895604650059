import React, { useState, useEffect } from "react";

import axios from "axios";
import Config from "../../config.json";
import Cookies from "js-cookie";

const IzlagacPredstavnici = (props) => {
  const [popis, setPopis] = useState([]);

  const [upisiNovogForma, setUpisiNovogForma] = useState(false);

  const [ime, setIme] = useState("");
  const [prezime, setPrezime] = useState("");
  const [funkcija, setFunkcija] = useState("");
  const [email, setEmail] = useState("");
  const [mobitel, setMobitel] = useState("");
  const [djelatnost, setDjelatnost] = useState("");

  const [kopiranjePredstavnika, setKopiranjePredstavnika] = useState(false);
  const [predstavnikZaKopiranje, setPredstavnikZaKopiranje] = useState({});

  useEffect(() => {
    ucitajPredstavnike();
  }, []);

  const ucitajPredstavnike = async () => {
    let url = Config.apiEndpoint + "/event/izlagac/predstavnici";
    const response = await axios.post(
      url,
      {
        izlagac: props.izlagac,
      },
      {
        headers: {
          authorization: Cookies.get("token"),
        },
      }
    );
    console.log('Predstavnici:', response);
    setPopis(response.data);
  };

  const snimi = async (event) => {
    console.log(event);
    event.preventDefault();

    //const dupliEmail = popis.filter(predstavnik => predstavnik.email === email);
    //console.log(dupliEmail.length);

    let url = Config.apiEndpoint + "/event/izlagac/dodaj-predstavnika";
    const response = await axios.post(
      url,
      {
        ime: ime,
        prezime: prezime,
        funkcija: funkcija,
        email: email,
        mobitel: mobitel,
        djelatnost: djelatnost,
        izlagac: props.izlagac,
      },
      {
        headers: {
          authorization: Cookies.get("token"),
        },
      }
    );

    console.log("INSERT ID: ", response.data.insertId);

    ucitajPredstavnike();

    const insertId = response.data;

    setIme("");
    setPrezime("");
    setFunkcija("");
    setEmail("");
    setMobitel("");
    setDjelatnost("");

    setUpisiNovogForma(false);
  };

  const provjeriPredstavnika = async (email) => {
    const url = Config.apiEndpoint + "/event/izlagac/provjeri-predstavnika";
    const response = await axios.post(url,
      {
        email
      },
      {
        headers: {
          authorization: Cookies.get("token")
        }
      });
      if (response.data.postoji) {
        setPredstavnikZaKopiranje(response.data.predstavnik);
        console.log("Predstavnik postoji.")
        //setKopiranjePredstavnika(true);

      } else {
        //alert("Predstavnik ne postoji...")
        console.log("Predstavnik ne postoji.");
      }
  }

  return (
    <div
      className='mt-5'
      style={{ backgroundColor: "#ede9e8", padding: "20px" }}
    >
      <div style={{ fontSize: "18px", fontWeight: "700" }}>
        Predstavnici izlagača
      </div>
      <div className='text-right mb-4'>
        <button
          className='btn btn-secondary'
          onClick={() => setUpisiNovogForma(!upisiNovogForma)}
        >
          Upiši novog
        </button>
      </div>
      {kopiranjePredstavnika && (
        <div>
        <div>Predstavnik za kopiranje: </div>
      <div>{predstavnikZaKopiranje.id}</div>
      <div>{predstavnikZaKopiranje.ime}</div>
      <div>{predstavnikZaKopiranje.prezime}</div>
      <div>{predstavnikZaKopiranje.email}</div>
      <div><button onClick={() => setKopiranjePredstavnika(false)}>Kopiraj</button></div>
      <div><button>Odustani</button></div>
      </div>
      )}
      {upisiNovogForma && !kopiranjePredstavnika && (
        <form onSubmit={snimi}>
          <div className='form-group row'>
            <label htmlFor='ime' className='col-sm-6 text-right'>
              Ime
            </label>
            <div className='col-sm-6 text-left'>
              <input
                type='text'
                name='ime'
                value={ime}
                onChange={(event) => setIme(event.target.value)}
              />
            </div>
            <label htmlFor='prezime' className='col-sm-6 text-right'>
              Prezime
            </label>
            <div className='col-sm-6 text-left'>
              <input
                type='text'
                name='prezime'
                value={prezime}
                onChange={(event) => setPrezime(event.target.value)}
              />
            </div>
            <label htmlFor='email' className='col-sm-6 text-right'>
              E-mail
            </label>
            <div className='col-sm-6 text-left'>
              <input
                type='text'
                name='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                //onBlur={(event) => provjeriPredstavnika(event.target.value)}
              />
            </div>
            <label htmlFor='funkcija' className='col-sm-6 text-right'>
              Funkcija
            </label>
            <div className='col-sm-6 text-left'>
              <input
                type='text'
                name='funkcija'
                value={funkcija || "Predstavnik"}
                onChange={(event) => setFunkcija(event.target.value)}
              />
            </div>
            
            <label htmlFor='mobitel' className='col-sm-6 text-right'>
              Mobitel
            </label>
            <div className='col-sm-6 text-left'>
              <input
                type='text'
                name='mobitel'
                value={mobitel}
                onChange={(event) => setMobitel(event.target.value)}
              />
            </div>
            <label htmlFor='djelatnost' className='col-sm-6 text-right'>
              Djelatnost
            </label>
            <div className='col-sm-6 text-left'>
              <input
                type='text'
                name='djelatnost'
                value={djelatnost || "Ostalo"}
                onChange={(event) => setDjelatnost(event.target.value)}
              />
            </div>
            <div className='col-sm-12 text-center'>
              <button className='btn btn-success'>Snimi</button>
            </div>
          </div>
        </form>
      )}
      <table className='table'>
        <thead>
          <tr>
            {/* <th scope='col' style={{ textAlign: "left" }}>
              ID
            </th> */}
            <th scope='col' style={{ textAlign: "left" }}>
              Ime
            </th>
            <th scope='col' style={{ textAlign: "left" }}>
              Prezime
            </th>
            <th scope='col' style={{ textAlign: "left" }}>
              E-mail
            </th>
            <th scope='col' style={{ textAlign: "left" }}>
              Password
            </th>
            {/* <th scope='col' className='text-center'>
              Glavni
            </th> */}
            {/* <th scope="col" style={{ textAlign: "left" }}>
              Izmjena
            </th> */}
          </tr>
        </thead>
        <tbody>
          {popis.map((predstavnik) => (
            <tr key={predstavnik.id}>
              {/* <td>
                <input type='text' value={predstavnik.id} readOnly />
              </td> */}
              <td>
                <input type='text' value={predstavnik.ime} readOnly />
              </td>
              <td>
                <input type='text' value={predstavnik.prezime} readOnly />
              </td>
              <td>
                <input
                  type='text'
                  value={predstavnik.email}
                  readOnly
                  style={{ width: "350px" }}
                />
              </td>
              <td>
                <input
                  type='password'
                  value={predstavnik.password}
                  style={{ width: "70px", textAlign: "center" }}
                  onFocus={(e) => (e.target.type = "text")}
                  onBlur={(e) => (e.target.type = "password")}
                  readOnly
                  style={{ width: "80px" }}
                />
              </td>
              {/* <td className='text-center'>
                <input type='radio' group='glavni' name='glavni' id='' />
              </td> */}
              {/* <td>
                <button
                  className="btn btn-primary"
                  onClick={() => snimi(predstavnik)}
                >
                  Snimi
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IzlagacPredstavnici;
