import React, { Component } from "react";

import Logo from "../../images/logoHA2020.png";

import "./PrintZebra.css";

class PrintToZebra extends Component {
  state = {
    tvrtka: "",
  };

  render() {
    const {
      ime,
      prezime,
      tvrtka,
      grad_mjesto,
      friendlyid,
      barcode,
      kategorija,
    } = this.props.osoba;
    return (
      <div
        id='print'
        style={{
          paddingTop: "0mm",
          marginLeft: "128px",
          marginTop: "137px",
          textAlign: "left",
          backgroundColor: "white",
        }}
      >
        <div style={{ width: "100mm", textAlign: "center" }}>
          <div
            className='posjetitelj_rotate rotate'
            style={{ transform: "rotate(180deg)", marginTop: "25mm" }}
          >
            {kategorija}
          </div>
          <div className='friendlyid_rotate rotate'>{friendlyid}</div>
          <div
            id='qrcode_rotated rotate'
            style={{
              margin: "0px auto",
              marginTop: "20px",
              display: "inline-block",
            }}
          >
            <img
              src={`https://api.horeca-adria.com/prijava/barcode.php?barcode=${barcode}`}
              alt='barcode'
              style={{ height: "60px", width: "250px" }}
            />
          </div>
          <div className='mjesto_rotated rotate'>{grad_mjesto}</div>
          <div className='tvrtka_rotated rotate'>{tvrtka}</div>
          <div className='prezime_rotated rotate'>{prezime}</div>
          <div className='ime_rotated rotate'>{ime}</div>
          <div className='logo_rotated rotate'>
            <img src={Logo} alt='' />
          </div>

          <div className='logo'>
            <img src={Logo} alt='' />
          </div>
          <div className='ime'>{ime}</div>
          <div className='prezime'>{prezime}</div>
          <div className='tvrtka'>{tvrtka}</div>
          <div className='mjesto'>{grad_mjesto}</div>
          <div className='qrcode'>
            <img
              src={`https://api.horeca-adria.com/prijava/barcode.php?barcode=${barcode}`}
              alt='barcode'
              style={{ height: "60px", width: "250px" }}
            />
          </div>
          <div className='friendlyid' style={{ textAlign: "left" }}>
            {friendlyid}
          </div>
          <div className='posjetitelj' style={{ marginTop: "5px" }}>
            {kategorija}
          </div>
        </div>
      </div>
    );
  }
}

export default PrintToZebra;
