import React from "react";

import axios from "axios";
import Config from "../../config.json";
import Cookies from "js-cookie";

const IzlagacImport = (props) => {
  const importIzlagaca = async (izlagac) => {
    console.log(izlagac);
    const url = Config.apiEndpoint + "/event/izlagac/create";
    const response = await axios.post(
      url,
      {
        eventid: 102,
        naziv: izlagac.naziv,
        email: izlagac.email,
        adresa: izlagac.adresa,
        postanski_broj: izlagac.postanski_broj,
        mjesto: izlagac.grad_mjesto,
        drzava: izlagac.drzava,
        telefon: izlagac.telefon,
        oib: izlagac.oib,
        web_adresa: izlagac.web_adresa,
        pozicija: izlagac.broj_standa,
        opis: izlagac.opis,
      },
      {
        headers: {
          authorization: Cookies.get("token"),
        },
      }
    );
    console.log(response);
    props.onImport();
  };
  return (
    <table className='table table-sm'>
      <thead>
        <tr>
          <th className='naslov-popis'>Naziv</th>
          <th className='naslov-popis'>Mjesto</th>
          <th className></th>
        </tr>
      </thead>
      <tbody>
        {props.popisZaImport.map((izlagac) => (
          <tr key={izlagac.id}>
            <td>{izlagac.naziv}</td>
            <td>{izlagac.grad_mjesto}</td>
            <td
              onClick={() => importIzlagaca(izlagac)}
              style={{ cursor: "pointer" }}
            >
              Import na HoReCa Adria 2020
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default IzlagacImport;
