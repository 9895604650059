import React, { Component } from "react";
import LeftNavBar from "./LeftNavBar";

class Keywords extends Component {
  state = {
    inputKeyword: "",
    keywords: []
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    fetch("http://localhost:8081/keywords/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log("test");
        console.log(res);
        this.setState({
          keywords: res
        });
      })
      .catch(err => console.log(err));
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = () => {
    fetch("http://localhost:8081/keywords/insert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        keyword: this.state.inputKeyword
      })
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          inputKeyword: ""
        });
        this.refresh();
      });
  };

  render() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <LeftNavBar />
            <div className="col">
              <h2>Keywordovi</h2>
              {this.state.keywords.length === 0 ? (
                <h2>Nema keyworda</h2>
              ) : (
                this.state.keywords.map(keyword => (
                  <h5 key={keyword.id}>{keyword.keyword}</h5>
                ))
              )}
              <input
                type="text"
                name="inputKeyword"
                value={this.state.inputKeyword}
                onChange={this.handleChange}
              />
              <button
                className="btn btn-success m-2"
                onClick={this.handleSubmit}
              >
                Upis novog keyworda
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Keywords;
