import React, { Component } from "react";

import Cookies from "js-cookie";

import Config from "../../config.json";

class IzlagacForma extends Component {
  state = {
    naziv: "",
    mjesto: "",
    email: "",
    adresa: "",
    postanski_broj: "",
    drzava: "",
    telefon: "",
    pozicija: "",
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const url = Config.apiEndpoint + "/event/izlagac/create";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: Cookies.get("token"),
      },
      body: JSON.stringify({
        organizator: 1,
        eventid: this.props.eventid,
        naziv: this.state.naziv,
        mjesto: this.state.mjesto,
        email: this.state.email,
        adresa: this.state.adresa,
        postanski_broj: this.state.postanski_broj,
        drzava: this.state.drzava,
        telefon: this.state.telefon,
        pozicija: this.state.pozicija,
      }),
    })
      .then((res) => {
        this.props.zatvoriFormu();
        this.props.refresh();
        //console.table(res);
        //const { event } = this.props.match.params;
        //this.props.history.push(`/event/${event}/izlagaci/list`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className='mb-5'>
        <form onSubmit={this.handleSubmit}>
          <div className='form-group'>
            <label htmlFor='naziv'>Naziv izlagača</label>
            <input
              type='text'
              className='form-control'
              name='naziv'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>E-mail</label>
            <input
              type='text'
              className='form-control'
              name='email'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='adresa'>Adresa</label>
            <input
              type='text'
              className='form-control'
              name='adresa'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='postanski_broj'>Poštanski broj</label>
            <input
              type='text'
              className='form-control'
              name='postanski_broj'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='mjesto'>Mjesto</label>
            <input
              type='text'
              className='form-control'
              name='mjesto'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='drzava'>Država</label>
            <input
              type='text'
              className='form-control'
              name='drzava'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='telefon'>Telefon</label>
            <input
              type='text'
              className='form-control'
              name='telefon'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='pozicija'>Pozicija</label>
            <input
              type='text'
              className='form-control'
              name='pozicija'
              onChange={this.handleChange}
            />
          </div>
          <button className='btn btn-primary'>Upiši izlagača</button>
        </form>
      </div>
    );
  }
}

export default IzlagacForma;
