import React from "react";

const Marketing = () => {
  return (
    <div>
      <div>Marketing</div>
    </div>
  );
};

export default Marketing;
