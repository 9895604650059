import React, { Component } from "react";

import jwt from "jsonwebtoken";
import Cookies from "js-cookie";
import Config from "../../config.json";
import axios from "axios";
import Izlagaci from "./Izlagaci";
import { Modal, Accordion, Card, Button } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EventDjelatnosti from "./EventDjelatnosti.jsx";
import EventKategorije from "./EventKategorije.jsx";
import CheckList from "../Common/CheckList.jsx";

class UpisEventa extends Component {
  state = {
    naziv: "",
    info: "",
    logo: null,
    datumod: "",
    datumdo: "",
    adresa: "",
    showModal: false
  };

  checkFormData = () => {
    const formData = new FormData(document.getElementById("forma"));
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleDateStartChange = date => {
    this.setState({
      datumod: date
    });
  };

  handleDateEndChange = date => {
    this.setState({
      datumdo: date
    });
  };

  fileSelectedHandler = event => {
    this.setState({
      logo: event.target.files[0]
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const formData = new FormData(document.getElementById("forma"));
    formData.append("organizator", jwt.decode(Cookies.get("token")).id);
    fetch(Config.apiEndpoint + "/event/create/", {
      method: "POST",
      //headers: { "Content-Type": "multipart/form-data" },
      body: formData
    })
      .then(res => res.json())
      .then(res => {
        fetch(Config.apiEndpoint + "/event/logout", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            organizator: jwt.decode(Cookies.get("token")).id
          })
        })
          .then(res => {
            console.table(res);
            //this.props.history.push("/event");
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  };

  prikaziDetalje = event => {
    event.preventDefault();
    this.setState({ showModal: true });
  };

  zatvoriDetalje = event => {
    event.preventDefault();
    this.setState({ showModal: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-6 offset-3">
          <Modal show={this.state.showModal} centered={true} size="lg">
            <button onClick={event => this.zatvoriDetalje(event)}>
              Zatvori
            </button>
            <div
              style={{
                fontWeight: "700",
                fontSize: "17px",
                textAlign: "center"
              }}
            >
              Import podataka iz prethodnog eventa
            </div>
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Izlagači
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Izlagaci event="100" noviEvent={true} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Djelatnosti
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <EventDjelatnosti noviEvent={true} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Kategorije
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <EventKategorije event="100" noviEvent={true} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Modal>
          <h4>Upis novog eventa</h4>
          <form onSubmit={this.handleSubmit} id="forma">
            <div className="form-group">
              <label htmlFor="naziv">Naziv eventa</label>
              <input
                type="text"
                className="form-control"
                id="naziv"
                name="naziv"
                aria-describedby="emailHelp"
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="info">Info o eventu</label>
              <textarea
                className="form-control"
                id="info"
                name="info"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="logo">Logo</label>
              <input
                type="file"
                className="form-control"
                id="logo"
                name="logo"
                onChange={this.fileSelectedHandler}
              />
            </div>
            <div className="form-group">
              <label htmlFor="datumod">Datum početka eventa</label>
              <br />
              <DatePicker
                selected={this.state.datumod}
                onChange={this.handleDateStartChange}
                dateFormat="dd.MM.yyyy."
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="datumdo">Datum završetka eventa</label>
              <br />
              <DatePicker
                selected={this.state.datumdo}
                onChange={this.handleDateEndChange}
                dateFormat="dd.MM.yyyy."
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="adresa">Adresa održavanja eventa</label>
              <input
                type="text"
                className="form-control"
                id="adresa"
                name="adresa"
                onChange={this.handleChange}
              />
            </div>
            <div>
              <div>Import podataka iz prethodnih evenata</div>
              <div
                className="col-3 offset-4"
                style={{
                  border: "1px solid black",
                  marginTop: "20px",
                  marginBottom: "20px",
                  textAlign: "left"
                }}
              >
                <label for="event">Izaberi event</label>
                <select
                  name="event"
                  style={{
                    width: "100px",
                    marginTop: "10px",
                    marginBottom: "10px"
                  }}
                >
                  <option value=""></option>
                  <option value="">HoReCa Adria 2019</option>
                  <option value="">Relax Adria 2019</option>
                  <option value="">HoReCa Adria 2020</option>
                </select>
                <input id="izlagaci" type="checkbox" />
                <label for="izlagaci">Izlagači</label>
                <br />
                <input id="osobe" type="checkbox" />
                <label for="osobe">Osobe</label>
                <br />
                <input id="djelatnosti" type="checkbox" />
                <label for="djelatnosti">Djelatnosti</label>
                <br />
                <input id="kategorije" type="checkbox" />
                <label for="kategorije">Kategorije</label>
                <CheckList
                  list={[
                    { value: "test1", checked: false },
                    { value: "test2", checked: true }
                  ]}
                />
                <button onClick={e => this.prikaziDetalje(e)}>Detalji</button>
              </div>
            </div>
            <div>
              <div>Potrebni podaci za registraciju</div>
              <div
                className="col-4 offset-4"
                style={{
                  border: "1px solid black",
                  marginTop: "20px",
                  marginBottom: "20px",
                  textAlign: "left"
                }}
              >
                <input id="oslovljavanje" type="checkbox" checked />
                <label for="oslovljavanje">Oslovljavanje</label>
                <br />
                <input id="ime" type="checkbox" checked />
                <label for="ime">Ime</label>
                <br />
                <input id="prezime" type="checkbox" checked />
                <label for="prezime">Prezime</label>
                <br />
                <input id="email" type="checkbox" checked />
                <label for="email">E-mail</label>
                <br />
                <input id="tvrtka" type="checkbox" />
                <label for="tvrtka">Tvrtka</label>
                <br />
                <input id="funkcija" type="checkbox" />
                <label for="funkcija">Funkcija</label>
                <br />
                <input id="djelatnost" type="checkbox" checked />
                <label for="djelatnost">Djelatnost</label>
                <br />
                <input id="telefon" type="checkbox" checked />
                <label for="telefon">Telefon</label>
                <br />
                <input id="adresa" type="checkbox" />
                <label for="adresa">Adresa</label>
                <br />
                <input id="postanski_broj" type="checkbox" />
                <label for="postanski_broj">Poštanski broj</label>
                <br />
                <input id="grad_mjesto" type="checkbox" />
                <label for="grad_mjesto">Grad</label>
                <br />
                <input id="drzava" type="checkbox" />
                <label for="drzava">Država</label>
                <br />
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Upis
            </button>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default UpisEventa;
