import React, { Component } from "react";

import axios from "axios";
import Config from "../../config.json";

class UpisDjelatnosti extends Component {
  state = { naziv: "" };

  handleChange = event => {
    this.setState({
      naziv: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const event_id = 100;
    const url = Config.apiEndpoint + "/event/upis-djelatnosti";
    const response = await axios.post(url, {
      event_id: event_id,
      naziv: this.state.naziv
    });
    console.log(response);
    this.props.history.push(
      `/event/${this.props.match.params.id}/djelatnosti/`
    );
  };

  render() {
    return (
      <React.Fragment>
        <h4>Upis nove djelatnosti</h4>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="naziv">Naziv djelatnosti</label>
          <input
            type="text"
            name="naziv"
            onChange={this.handleChange}
            autoFocus
          />
          <button className="btn btn-primary">Save</button>
        </form>
      </React.Fragment>
    );
  }
}

export default UpisDjelatnosti;
