import React, { useEffect } from "react";
import config from "./config.json";
import axios from "axios";
import { useSelector } from "react-redux";
import jwt from "jsonwebtoken";

const HomePage = () => {
  const data = useSelector(state => state);

  useEffect(() => {
    auth();
  }, []);

  const auth = async () => {
    if (data.token) {
      const response = await axios.get(config.apiEndpoint + "/check-token", {
        headers: {
          token: data.token,
          active_user: jwt.decode(data.token).id
        }
      });
      if (response.data.response !== "Check passed") {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  };

  return <div>{/*}
      <LeftNavBar />
  */}</div>;
};

export default HomePage;
