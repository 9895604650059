import React, { Component } from "react";

import Config from "../../config.json";
import PrintZebra from "./PrintZebra.jsx";
import PrintComponents from "react-print-components";
import PrintToZebra from "./PrintToZebra";
import ReactToPrint from "react-to-print";

import PrintButton from "./PrintButton";

import "./UpisOsobe.css";

import axios from "axios";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";

class UpisOsobe extends Component {
  state = {
    osoba: {
      oslovljavanje: "",
      ime: "",
      prezime: "",
      email: "",
      telefon: "",
      mobitel: "",
      tvrtka: "",
      funkcija: "",
      djelatnost: "",
      djelatnostList: [],
      adresa: "",
      postanski_broj: "",
      grad_mjesto: "",
      drzava: "HR",
      drzavaList: [],
      kategorija: "Posjetitelj",
      kategorijaList: [],
      vezane_novosti: false,
      nevezane_novosti: false,
      novosti_o_proizvodima: false,
      barcode: "",
      friendlyid: "",
      azurirano: false,
      vrsta_registracije: "Sajam",
    },
    oslovljavanje: "",
    ime: "",
    prezime: "",
    email: "",
    telefon: "",
    mobitel: "",
    tvrtka: "",
    funkcija: "",
    djelatnost: "",
    djelatnostList: [],
    adresa: "",
    postanski_broj: "",
    grad_mjesto: "",
    drzava: "HR",
    drzavaList: [],
    kategorija: "Posjetitelj",
    kategorijaList: [],
    vezane_novosti: false,
    nevezane_novosti: false,
    novosti_o_proizvodima: false,
    barcode: "",
    friendlyid: "",
    azurirano: false,
    vrsta_registracije: "Sajam",
    username: jwt.decode(Cookies.get("token")).username !== "infolab",
  };

  componentDidMount() {
    if (this.props.osoba.ime) {
      console.log("Učitana osoba", this.props.osoba);
    }
    this.getDjelatnostiList();
    this.getKategorijeList();
    //this.getPopisDrzava();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.osoba !== this.props.osoba) {
      this.setState({ osoba: this.props.osoba });
    }
  }

  handleChange = (event) => {
    this.setState({
      osoba: {
        ...this.state.osoba,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    //console.log(this.state);
    let url = "";
    {
      this.state.osoba.id
        ? (url = Config.apiEndpoint + "/event/102/osobe/edit")
        : (url = Config.apiEndpoint + "/event/102/osobe/create");
    }
    try {
      const response = await axios.post(
        url,
        {
          data: this.state.osoba,
        },
        {
          headers: {
            authorization: Cookies.get("token"),
          },
        }
      );
      console.log(response);
      this.state.osoba.id
        ? response.data.affectedRows && this.setState({ azurirano: true })
        : this.setState({
            azurirano: true,
            osoba: {
              ...this.state.osoba,
              barcode: response.data.barcode,
              friendlyid: response.data.friendlyid,
            },
          });
    } catch (error) {
      alert("Problem sa spajanjem na server.");
    }

    //this.state.osoba.id && this.props.hideModal();
  };

  getDjelatnostiList = () => {
    fetch(`${Config.apiEndpoint}/event/djelatnosti/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        event: 102,
        authorization: Cookies.get("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          djelatnostList: res,
        });
      })
      .catch((err) => console.log(err));
  };

  getKategorijeList = () => {
    fetch(`${Config.apiEndpoint}/event/categories/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        event: 102,
        authorization: Cookies.get("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        this.setState({
          kategorijaList: res,
        });
      })
      .catch((err) => console.log(err));
  };

  getPopisDrzava = () => {
    fetch("https://restcountries.eu/rest/v2/all", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          drzavaList: res,
        });
      });
  };

  closeModalAfterPrint = () => {
    //this.props.afterPrint;
  };

  render() {
    return (
      <div className='container screen'>
        <form onSubmit={this.handleSubmit}>
          <div className='form-group row' style={{ marginBottom: 0 }}>
            <label
              htmlFor='ime'
              className='col-sm-6 col-form-label font-weight-bold text-left'
            >
              Ime
            </label>
            <label
              htmlFor='prezime'
              className='col-sm-6 col-form-label font-weight-bold text-left'
            >
              Prezime
            </label>
          </div>
          <div className='form-group row'>
            <div className='col-sm-6'>
              <input
                type='text'
                className='form-control font-weight-bold'
                style={{ height: "60px", fontSize: "30px" }}
                id='ime'
                name='ime'
                value={this.state.osoba.ime}
                onChange={this.handleChange}
                required
                disabled={this.state.osoba.id}
                autoComplete='off'
              />
            </div>
            <div className='col-sm-6'>
              <input
                type='text'
                className='form-control font-weight-bold'
                style={{ height: "60px", fontSize: "30px" }}
                id='prezime'
                name='prezime'
                value={this.state.osoba.prezime}
                onChange={this.handleChange}
                required
                disabled={this.state.osoba.id}
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='tvrtka'
              className='col-sm-2 col-form-label font-weight-bold'
              style={{ height: "60px" }}
            >
              Tvrtka
            </label>
            <div className='col-sm-10'>
              <input
                type='text'
                className='form-control font-weight-bold'
                style={{ height: "60px", fontSize: "30px" }}
                id='tvrtka'
                name='tvrtka'
                value={this.state.osoba.tvrtka}
                onChange={this.handleChange}
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='adresa'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Adresa
            </label>
            <div className='col-sm-10'>
              <input
                type='text'
                className='form-control font-weight-bold'
                id='adresa'
                name='adresa'
                value={this.state.osoba.adresa}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='postanski_broj'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Poštanski br.
            </label>
            <div className='col-sm-2'>
              <input
                type='text'
                className='form-control font-weight-bold'
                id='postanski_broj'
                name='postanski_broj'
                value={this.state.osoba.postanski_broj}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
            <label
              htmlFor='grad_mjesto'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Grad
            </label>
            <div className='col-sm-6'>
              <input
                type='text'
                className='form-control font-weight-bold'
                id='grad_mjesto'
                name='grad_mjesto'
                value={this.state.osoba.grad_mjesto}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='email'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              E-mail
            </label>
            <div className='col-sm-10'>
              <input
                type='email'
                className='form-control font-weight-bold'
                id='email'
                name='email'
                value={this.state.osoba.email}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='funkcija'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Funkcija
            </label>
            <div className='col-sm-4 font-weight-bold'>
              <input
                type='text'
                className='form-control font-weight-bold'
                id='funkcija'
                name='funkcija'
                value={this.state.osoba.funkcija}
                onChange={this.handleChange}
                autoComplete='off'
              />
            </div>
            <label
              htmlFor='djelatnost'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Djelatnost
            </label>
            <div className='col-sm-4'>
              <select
                type='text'
                className='form-control font-weight-bold'
                id='djelatnost'
                name='djelatnost'
                value={this.state.osoba.djelatnost}
                onChange={this.handleChange}
                required
              >
                <option value='' />
                {this.state.djelatnostList.map((djelatnost) => (
                  <option
                    key={djelatnost.id}
                    //selected={this.state.osoba.djelatnost === djelatnost.naziv}
                  >
                    {djelatnost.naziv}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='telefon'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Telefon
            </label>
            <div className='col-sm-4'>
              <input
                type='phone'
                className='form-control font-weight-bold'
                id='telefon'
                name='telefon'
                value={this.state.osoba.telefon}
                onChange={this.handleChange}
                required
                autoComplete='off'
              />
            </div>

            <label
              htmlFor='mobitel'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Mobitel
            </label>
            <div className='col-sm-4'>
              <input
                type='mobile'
                className='form-control font-weight-bold'
                id='mobitel'
                name='mobitel'
                value={this.state.osoba.mobitel}
                onChange={this.handleChange}
                autoComplete='off'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='drzava'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Država
            </label>
            <div className='col-sm-4'>
              <select
                type='text'
                className='form-control font-weight-bold'
                id='drzava'
                name='drzava'
                value={this.state.osoba.drzava}
                onChange={this.handleChange}
                required
              >
                <option value='HR'>Hrvatska</option>
                <option value='SI'>Slovenija</option>
                <option value='HU'>Mađarska</option>
                <option value='AT'>Austrija</option>
                <option value='DE'>Njemačka</option>
                <option value='BA'>Bosna i Hercegovina</option>
                <option value='RS'>Srbija</option>
                <option value='CG'>Crna Gora</option>
                <option value='OS'>Ostalo</option>
              </select>
            </div>
            <label
              htmlFor='kategorija'
              className='col-sm-2 col-form-label font-weight-bold'
            >
              Kategorija
            </label>
            <div className='col-sm-4'>
              <select
                type='text'
                className='form-control font-weight-bold'
                style={{ backgroundColor: "white" }}
                id='kategorija'
                name='kategorija'
                value={
                  !this.props.edit ? "Posjetitelj" : this.state.osoba.kategorija
                }
                onChange={this.handleChange}
                required
                disabled={this.state.username}
              >
                <option value='' />
                {this.state.kategorijaList.map((kategorija) => (
                  <option key={kategorija.id} value={kategorija.naziv}>
                    {kategorija.naziv}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-sm-12 mt-5'>
            {this.props.edit ? (
              <button
                type='submit'
                className='btn btn-primary'
                style={{ marginRight: "30px" }}
              >
                Promijeni
              </button>
            ) : (
              !this.state.azurirano && (
                <button
                  type='submit'
                  className='btn btn-primary'
                  style={{
                    marginRight: "30px",
                    backgroundColor: "#0069d9",
                    borderColor: "#0069d9",
                    width: "145px",
                  }}
                >
                  Upiši
                </button>
              )
            )}
            {this.state.osoba.barcode && (
              <PrintComponents
                trigger={
                  <div
                    className='btn btn-success cursor'
                    style={{
                      backgroundColor: "#218838",
                      borderColor: "#218838",
                      width: "145px",
                      cursor: "pointer",
                    }}
                  >
                    Ispis akreditacije
                  </div>
                }
              >
                <PrintZebra osoba={this.state.osoba} />
              </PrintComponents>
            )}
            {this.state.azurirano && <div>Upisano / Ažurirano!</div>}
          </div>
        </form>
        {this.state.osoba.barcode && <div></div>}
      </div>
    );
  }
}

export default UpisOsobe;