import React, { Component } from "react";

import axios from "axios";

import Config from "../../config.json";

import "./EventDjelatnosti.css";

class EventDjelatnosti extends Component {
  state = {
    popis: []
  };

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    const url = Config.apiEndpoint + "/event/100/djelatnosti/list";
    const response = await axios.get(url, {
      headers: {
        event: 100
      }
    });
    this.setState({ popis: response.data });
  };

  render() {
    return (
      <div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              {/* <div className="text-right">
                <Link
                  to={`/event/djelatnosti/upis/${this.props.match.params.event}`}
                >
                  <button className="btn btn-primary mb-2">
                    Upis nove djelatnosti
                  </button>
                </Link>
              </div> */}
              <table className='table'>
                <thead>
                  <tr>
                    <th>Naziv djelatnosti</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.popis.map(djelatnost => (
                    <tr
                      key='djelatnosti.id'
                      className={
                        this.props.noviEvent
                          ? "novi-event-import-row"
                          : "event-djelatnosti-row"
                      }
                    >
                      <td>
                        {this.props.noviEvent && (
                          <input type='checkbox' checked />
                        )}
                        {djelatnost.naziv}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventDjelatnosti;
